

@media only screen and (max-width: 575px) {
    .mobile-menu {
        height: 75px
    }

    .mobile-content {
        position: absolute;
        top: 75px;
    }

}