// variables that need accessed from other page specific stylesheets

// Custom colors
$primary-on-light: #7B6639; // accessible gold on top of platnum

// Customize bootstrap defaults
$primary: #8A7340;
$light: #E8E8E8;
$success: #0f5132;

//Content URL
$content-url: 'https://fileresources.titleonecorp.com';

// Adding for access to other stylesheets with only import of t1variables.scss, make sure they match what is in t1bootstrap.scss for consistency
$line-height-base: 1.75;
$line-height-sm: 1.25;
$line-height-lg: 2.2;

