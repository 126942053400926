@import '../../t1variables.scss';

.preferred-customer-status-1 {
    background-color: #afa;
}

.preferred-customer-status-2, .preferred-customer-status-6, .preferred-customer-status-7, .preferred-customer-status-8 {
    background-color: #ffa;
}

.preferred-customer-status-3 {
    background-color: #c9f;
}

.preferred-customer-status-4 {
    background-color: #aaa;
}

.preferred-customer-status-5 {
    background-color: #faa;
}

.preferred-customer-status-9 {
    background-color: #ea0;
}