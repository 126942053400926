@import '../../t1variables.scss';

.golf-tournaments {
    .d-flex .data-grid-link {
        color: $primary-on-light;
        text-decoration: none;
    }
}

.MuiModal-root {

    .modal-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        background-color: #ffffff;
        box-shadow: 24px 24px 24px rgba(0, 0, 0, 0.2);
        padding: 16px;
    }

    .modal-dialog .submit-button, .modal-dialog .cancel-button {
        background-color: $primary;
        color: white;
        border: none;
        padding: 10px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 5px;
    }

    .modal-dialog .submit-button:hover, .modal-dialog .cancel-button:hover {
        background-color: #756236;
        color: white;
    }
}

