@import '../../../t1variables.scss';

.ecards {

    iframe.card-selection {
        width: 227px;
        height: 250px;
    }

    .iframe-wrap {
        width: 227px;
        height: 250px;
        overflow: hidden;
    }

    .iframe-wrap iframe {
        width: 680px;
        height: 750px;
        transform: scale(calc(1 / 3));
        transform-origin: 0 0;
    }

    .card:hover {
        box-shadow: 0 10px 20px #adadad !important;
    }
}

.ecard-preview-src {
    all: unset;
}

