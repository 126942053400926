@import '../../../t1variables.scss';
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";


.nav-link {
    color: white !important;
    padding-left: 2rem !important;
    text-indent: -1.5rem;
    line-height: $line-height-sm;
}