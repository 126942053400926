@import './t1variables.scss';
// import only what needs overridden
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/navbar";

// Add custom font
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
$font-family-base: 'Noto Sans', sans-serif;
$headings-font-family: 'Noto Sans', sans-serif;

// adjust heading size variables, additional, specifications in app.scss
$h1-font-size: $font-size-base * 2.4; 
$h2-font-size: $font-size-base * 1.6; 
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.1;
$h5-font-size: $font-size-base * 1.0;
$headings-margin-bottom: $spacer * .7;

// adjust line heights
$line-height-base: 1.75;
$line-height-sm: 1.25;
$line-height-lg: 2.2;


// override navbar spacing
$navbar-light-color: rgba($black, .80);
$navbar-nav-link-padding-x: .3rem;
$caret-width: .4em;

// carousel speeds
$carousel-transition-duration: 2s;
$carousel-transition: opacity $carousel-transition-duration linear;

$tooltip-max-width: 300px;

// import all of bootstrap to have overrides take effect and have all of bootstrap avialable to the entire site
@import "bootstrap/scss/bootstrap";