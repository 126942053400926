.image-container {
    position: fixed;
    bottom: 0;
    max-width: 100%; 
    text-align: center;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.fade-image {
    --mask: linear-gradient(to bottom, rgba(0,0,0, 1) 0, rgba(0,0,0, 1) 70%, rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0 ) 100% 50% / 100% 100% repeat-x;
    font: 2em/1.6em Arial;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.a2hs-header {
    font-size: 2rem;
    font-weight: bold;
    line-height: 40px;
}

.a2hs-body {
    font-size: 18px;
}

