@import '../../t1variables.scss';

.iotw {
    .white-popup {
        background-color: #FFFFFF;
        width: 656px;
        height: 422px;
        padding: 10px;
    }

    .iotw-border {
        border: 1px solid #adadad;
        height: 100%;
    }

    .modal_left {
        width: 55%;
        float: left;
        height:100%;
    }

    .modal_right {
        width: 45%;
        display: inline-block;
        overflow: hidden;
    }

    .modal_h1 {
        font-size: 24px;
        line-height: 30px;
    }

    .modal-dialog {
        margin: auto !important;
        max-width: 100% !important;
        pointer-events: all !important;
    }

    .iotw-image {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
    }

    .iotw-close {
        position: absolute;
        right: 10px;
        margin: 3px;
        border: solid 1px;
        border-color: rgba(var(--bs-primary-rgb), .5);
        --bs-bg-opacity: .5;
    }

    .iotw-close:hover {
        --bs-bg-opacity: .8;
    }
}


.modal.show.iotw {
    display: flex !important;
}


.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.wysiwyg-title {
    height: 100px;
}
